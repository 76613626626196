
@theme-bg-color : rgb(41, 171, 226);
@--tw-space-x-reverse: 0;
@theme-inp-bg-color:#54bce8;
.m2-002{
    h2{
        font-size: 1.875rem;
        line-height: 2.25rem;
        color: rgb(41, 171, 226);
        font-weight: 600;
    }
    .style2-header{
        padding: .75rem 2rem;
        display: flex;
        align-items: center;
        background-color: @theme-bg-color;
        justify-content: space-between;
        position: relative;
        img{
            max-height: 2.75rem;
        }
        img.eye-icon{
            position: absolute;
            width: 1rem;
            right: 14.5rem;
            top: 1.6rem;
        }
        input{
            margin-right: calc(0.75rem* @--tw-space-x-reverse);
            margin-left: calc(0.75rem * (1 - @--tw-space-x-reverse));
            border:1px solid rgba(255, 255, 255,);
            height: 2.1rem;
            background-color:@theme-inp-bg-color ;
            color: #FFF;
            padding-right: 1.8rem;
        }
        .input-submit{
            margin-right: calc(0.75rem* @--tw-space-x-reverse);
            margin-left: calc(0.75rem * (1 - @--tw-space-x-reverse));
            border:1px solid rgba(255, 255, 255,);
            padding: .5rem 1.25rem;
            border-radius: .25rem;
            background-color: @theme-inp-bg-color;
            color: #FFF;
        }
        a{
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-right: calc(0.75rem* @--tw-space-x-reverse);
            margin-left: calc(0.75rem * (1 - @--tw-space-x-reverse));
            color: #FFF;
        }
    }

    
    .ant-carousel{
        height: 760px;
        .img_wrap{
            height: 760px;
        }
    }

    // registr
    .style2-central{
        position: relative;
        height: 760px;
        overflow: hidden;
        .Central{
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.25rem;
            width: auto;
            height: 100%;
            background:  rgba(255, 255, 255, .9);
            width: 33.33333%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            .grid{
                display: grid;
                gap: 0.5rem;
                grid-template-columns: repeat(10, minmax(0, 1fr));
                margin-top: calc(1rem * (1 - @--tw-space-x-reverse));
                margin-bottom: calc(1rem * @--tw-space-x-reverse);
                p{
                    grid-column: span 3 / span 3;
                    color: rgba(0, 0, 0 ,.6);
                }
                input{
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding: .5rem .75rem;
                    color: #000;
                    grid-column: span 7 / span 7;
                    border:1px solid rgb(212, 212, 212,);
                }
            }
            .submit{
                margin-top: calc(1rem * (1 - @--tw-space-x-reverse));
                margin-bottom: calc(1rem * @--tw-space-x-reverse);
                --tw-bg-opacity: 1;
                background-color: @theme-inp-bg-color;
                padding: 0.8rem;
                border-radius: 0.25rem;
                width: 100%;
                -webkit-appearance: button;
                line-height: inherit;
                color: #FFF;
                border: none;
                cursor: pointer;
            }
        }
    }

    .style2-Footer{
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
        h2{
            padding-bottom: 3.5rem;
        }
        .sponsor-footer{
            padding: 3.5rem 2.5rem;
            text-align: center;
            max-width: 72rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            max-width: 72rem;
            .sponsor-ul-list{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                .sponsor-item{
                    width: 25%;
                    cursor: pointer;
                    font-size: .75rem;
                    line-height: 1rem;
                    img{
                        max-height: 120px;
                        padding-top: 1rem;
                    }
                }
            }
            
        }
    }
}