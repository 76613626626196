*{
	margin: 0;
	padding: 0;
}
@import url(./Toast/index.less);
// hayhar QRP page
@import url(./hayhar/index.less);

@import url(./style2/index.less);
// modal
@import url(./hayhar/modal.less);

@import url(./restrict/index.less);

@hack: true;@import '/buildDir/data/Less/antd-custom.less';